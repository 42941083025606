import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Categories from './pages/Categories';
import Questions from './pages/Questions';
import Users from './pages/Users';
import UserAnswers from './pages/Answers';
import CompanyList from './pages/CompanyList';
import CompanyDetail from './pages/CompanyDetail';
import CompanyRegistration from './pages/CompanyRegistration';
import JobList from './pages/JobList';
import JobDetail from './pages/JobDetail';
import JobUpload from './pages/JobUpload';
import IntervieweeList from './pages/IntervieweeList';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your theme
import IntervieweeItem from './components/IntervieweeItem';
import UserProfile from './pages/UserProfile';
import JobApplication from './pages/JobApplication';
import JobApplicantsList from './pages/JobApplicantsList';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/questions" element={<Questions />} />
                    <Route path="/categories/:categoryId/:category/questions" element={<Questions />} />
                    <Route path="/users/:userId/answers/:categoryId" element={<UserAnswers />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/companies/register" element={<CompanyRegistration />} />
                    <Route path="/companies/:companyId" element={<CompanyDetail />} />
                    <Route path="/companies" element={<CompanyList />} />
                    <Route path="/jobs" element={<JobList />} />
                    <Route path="/jobs/:jobId" element={<JobDetail />} />
                    <Route path="/jobs/:jobID/interviewees" element={<IntervieweeList />} />
                    <Route path="/interviewees/:jobID/:userID" element={<IntervieweeItem  />} />
                    <Route path="/users/:userID" element={<UserProfile  />} />
                    <Route path="/apply/:jobID" element={<JobApplication  />} />
                    <Route path="/jobs/:jobID/applicants" element={<JobApplicantsList  />} />

                    
                    <Route path="/companies/:companyId/add-job" element={<JobUpload />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;
