import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUsers } from '../api/api';
import { Card, CardContent, Typography, Button, Container, Grid } from '@mui/material';

interface User {
    user_id: number;
    username: string;
    email: string;
}

const UserList: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const users = await fetchUsers();
                setUsers(users);
            } catch (error) {
                console.error('Failed to fetch users', error);
            }
        };

        loadUsers();
    }, []);
    const navigate = useNavigate();

    const handleUserProfileNavigation = (userId: number) => {
        navigate(`/users/${userId}`);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Users
            </Typography>
            <Grid container spacing={3}>
                {users.map(user => (
                    <Grid item xs={12} sm={6} md={4} key={user.user_id}>
                        <Card onClick={()=>handleUserProfileNavigation(user.user_id)}  sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {user.username}
                                </Typography>
                                <Typography color="text.secondary">
                                    {user.email}
                                </Typography>
                                <Button 
                                    component={Link} 
                                    to={`/users/${user.user_id}/answers`} 
                                    variant="contained" 
                                    color="primary" 
                                    sx={{ mt: 2 }}
                                >
                                    View Answers
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default UserList;
