import React from 'react';
import { Paper, Typography, Grid, Avatar, Divider, IconButton } from '@mui/material';
import { User } from '../api/api';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CakeIcon from '@mui/icons-material/Cake'; // For age
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // For CV download

interface UserInfoProps {
    user: User;
}

const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    if (isNaN(birthDateObj.getTime())) {
        // If the birthDate is not a valid date, return 'Invalid date'
        return 'Invalid date';
    }

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
};

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
    console.log(user)
    return (
        <Paper elevation={3} sx={{ padding: 4, mb: 4, borderRadius: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4} textAlign="center">
                    <Avatar
                        sx={{ width: 100, height: 100, margin: 'auto' }}
                        src={'default-profile.png'} // Placeholder for profile picture
                        alt={user.username}
                    />
                    {/* CV Icon Button */}
                    {user.cv_url && (
                        <>
                            <br />

                            <IconButton

                                href={user.cv_url}
                                target="_blank"
                                sx={{ mt: 2 }}
                                aria-label="Download CV"
                            >
                                <Typography variant="body1" color="text.secondary">
                                     CV
                                </Typography>
                                <FileDownloadIcon />
                            </IconButton>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {user.username}
                    </Typography>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <WorkIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="text.secondary">
                                        {user.role || 'Not provided'}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>

                     
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <EmailIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="text.secondary">
                                        {user.email || 'Not provided'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <LocationOnIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="text.secondary">
                                        {user.location || 'Not provided'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <CakeIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="text.secondary">
                                        {user.birth_date ? `${calculateAge(user.birth_date)} years old` : 'Not provided'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
        </Paper>
    );
};

export default UserInfo;
