import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { Applicant, fetchApplicantsWithJobId } from '../api/api';
import { Container, Typography, Button, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';


const JobApplicantsList: React.FC = () => {
    const { jobID } = useParams<{ jobID: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [applicants, setapplicants] = useState<Applicant[] | undefined>(undefined);
    const [filterActive, setfilterActive] = useState(false)
    useEffect(() => {
        const fetchapplicants = async () => {
            try {
                const response = await fetchApplicantsWithJobId(Number(jobID));

                setapplicants(response);
            } catch (error) {
                setError('Error fetching applicants');
                console.error('Error fetching applicants:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchapplicants();
    }, [jobID]);

    const handleNavigateToDetails = (applicantId: number) => {
        navigate(`/applicants/${jobID}/${applicantId}`);
    };


    const [selectedApplicants, setSelectedApplicants] = useState<{ [key: number]: boolean }>({});
    const [sortOrder, setSortOrder] = useState<string>('name'); // Default sort by name
    const [filter, setFilter] = useState<string>(''); // Filter by name

    const handleSortChange = (event: SelectChangeEvent<string>) => {
        setSortOrder(event.target.value as string);
        console.log(event.target.value)
        if (event.target.value === "score") {
            if (applicants){
               
            }
                
        }
    };

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        setFilter(event.target.value as string);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, applicantId: number) => {
        setSelectedApplicants((old) => {
            return { ...old, [applicantId]: event.target.checked }; // Use square brackets for dynamic keys
        });
    };
    const [filterText, setfilterText] = useState("")
    const handleFilterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setfilterText(event.target.value)
    }
    const handleInvite = () => { }
    const handleReject = () => { }


    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Layout>
            <Container sx={{ mt: 8 }}>
                <Typography variant="h4" gutterBottom>Applications</Typography>

                {/* Action buttons for invite and reject */}
                <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ minWidth: 120, p: 2 }}>
                        <InputLabel>Filter</InputLabel>
                        <Select value={filter} onChange={handleFilterChange}>
                            <MenuItem value="name">Name</MenuItem>
                            <MenuItem value="location">Location</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 220, p: 2 }}>
                        <TextField label="filter" variant='outlined' value={filterText} onChange={handleFilterNameChange}>

                        </TextField>
                    </FormControl>
                    <div style={{ flex: 1 }}></div>
                    <FormControl sx={{ alignSelf: "flex-end", minWidth: 150, p: 2 }}>
                        <InputLabel>Sort By</InputLabel>
                        <Select value={sortOrder} onChange={handleSortChange}>
                            <MenuItem value="salary">Salary</MenuItem>
                            <MenuItem value="score">Match Score</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInvite}
                        disabled={Object.values(selectedApplicants).every(checked => !checked)}
                    >
                        Invite for Interview
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleReject}
                        disabled={Object.values(selectedApplicants).every(checked => !checked)}
                    >
                        Reject
                    </Button>
                </div>
                {applicants && applicants.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Salary</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Match Score</TableCell>
                                    <TableCell>Summary</TableCell>

                                    <TableCell>Detail</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applicants.map(applicant => (
                                    <TableRow key={applicant.id}>
                                        <TableCell><Checkbox onChange={(val) => handleChange(val, applicant.id)} /></TableCell>
                                        <TableCell>{applicant.name}</TableCell>
                                        <TableCell>{applicant.email}</TableCell>
                                        <TableCell>{applicant.phone}</TableCell>
                                        <TableCell>{applicant.salary}</TableCell>
                                        <TableCell>{applicant.location}</TableCell>
                                        <TableCell>{applicant.score}</TableCell>
                                        <TableCell>{applicant.cv_evaluation?.summary}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => { handleNavigateToDetails(applicant.id) }}
                                            >
                                                Details
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No interviewee data available.</Typography>
                )}
            </Container>
        </Layout>
    );
};

export default JobApplicantsList;
