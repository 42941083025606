import React from 'react';
import { Paper, Typography, Box, Grid, LinearProgress, Divider } from '@mui/material';
import { Evaluation } from './IntervieweeItem';

interface EvaluationProps {
    evaluation: Evaluation;
}

const EvaluationGraph: React.FC<EvaluationProps> = ({ evaluation }) => {
    const getProgressValue = (score: number) => (score / 10) * 100; // Convert score to percentage for progress bar

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                Interview Evaluation 
            </Typography>

            {/* CV Evaluation */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    CV Evaluation:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Qualifications: {evaluation.cv_evaluation.qualifications.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.cv_evaluation.qualifications.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Experience: {evaluation.cv_evaluation.experience.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.cv_evaluation.experience.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Skills: {evaluation.cv_evaluation.skills.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.cv_evaluation.skills.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Overall: {evaluation.cv_evaluation.overall.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.cv_evaluation.overall.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                            Comments: {evaluation.cv_evaluation.comments}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider />

            {/* Response Evaluation */}
            <Box sx={{ mt: 3, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Response Evaluation:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Accuracy: {evaluation.response_evaluation.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.response_evaluation.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Completeness: {evaluation.response_evaluation.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.response_evaluation.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />

            {/* Overall Score and Recommendations */}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" color="primary" gutterBottom>
                    Overall Score: {evaluation.overall_score}/10
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={getProgressValue(evaluation.overall_score)}
                    sx={{ mb: 2 }}
                />
                <Typography variant="body2" color="text.secondary">
                    Recommendations: {evaluation.recommendations}
                </Typography>
            </Box>
        </Paper>
    );
};

export default EvaluationGraph;
