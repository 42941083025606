import React, { useEffect, useState } from 'react';
import { fetchQuestions } from '../api/api';
import '../styles/QuestionList.css'; // Import the CSS file for styling

interface Question {
    question_id: number;
    question_text: string;
    is_optional: boolean;
    category_name?: string; // Include category name if available
}

interface QuestionListProps {
    categoryId?: number;
    category:string;
}

const QuestionList: React.FC<QuestionListProps> = ({category, categoryId }) => {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadQuestions = async () => {
            try {
                const questions = await fetchQuestions(categoryId);
                setQuestions(questions);
            } catch (error) {
                console.error('Failed to fetch questions:', error);
            } finally {
                setLoading(false);
            }
        };

        loadQuestions();
    }, [categoryId]);
    console.log(questions)
    return (
        <div className="question-list">
            <h2>Questions{category ? ` for Category ${category}` : ''}</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ul>
                    {questions.length > 0 ? (
                        questions.map((question) => (
                            <li key={question.question_id} className="question-item">
                                <p>{question.question_text}</p>
                                <span>{question.is_optional ? 'Optional' : 'Required'}</span>
                                {question.category_name && (
                                    <div className="category-name">
                                        <strong>Category:</strong> {question.category_name}
                                    </div>
                                )}
                            </li>
                        ))
                    ) : (
                        <p>No questions found.</p>
                    )}
                </ul>
            )}
        </div>
    );
};

export default QuestionList;
