import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, CircularProgress, Alert, Divider, Paper, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { deleteJobQuestions, fetchJobQuestions, submitJobQuestions, UpdateJobQuestions } from '../api/api';
import { Add, Delete, Edit } from '@mui/icons-material';

interface JobQuestion {
    id: number;
    question_text: string;
}

interface JobQuestionsListProps {
    jobId: number;
}

const JobQuestionsList: React.FC<JobQuestionsListProps> = ({ jobId }) => {
    const [questions, setQuestions] = useState<JobQuestion[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadQuestions = async () => {
            try {
                const questionsData = await fetchJobQuestions(jobId);
                setQuestions(questionsData);
            } catch (error) {
                setError('Failed to load job questions');
            } finally {
                setLoading(false);
            }
        };

        loadQuestions();
    }, [jobId]);

    const [newQuestion, setNewQuestion] = useState('');
    const [editingQuestion, setEditingQuestion] = useState<JobQuestion | null>(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const refreshQuestions = async () => {
        const questionsData = await fetchJobQuestions(jobId);
        setQuestions(questionsData);
    }
    const apiCallAddNewQuestion = async (question: string) => {
        await submitJobQuestions(jobId, [{ question, relevance: question }])
        refreshQuestions()
    }
    // Add a new question
    const handleAddQuestion = () => {
        if (newQuestion.trim()) {
            apiCallAddNewQuestion(newQuestion)
            setNewQuestion('');
        }
    };

    // Open the edit dialog
    const handleEditClick = (question: JobQuestion) => {
        setEditingQuestion(question);
        setEditDialogOpen(true);
    };
    const handleEditQuestion = () => {
        if (editingQuestion) {
            UpdateJobQuestions(editingQuestion.id, editingQuestion.question_text)
            setQuestions(questions.map(q =>
                q.id === editingQuestion.id ? { ...q, question_text: editingQuestion.question_text } : q
            ));
        }
        setEditDialogOpen(false);
        setEditingQuestion(null);
    };

    // Delete a question
    const handleDeleteQuestion = (id: number) => {
        deleteJobQuestions(id)
        setQuestions(questions.filter(question => question.id !== id));
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Paper style={{ padding: '16px' }}>
            <Typography variant="h6" gutterBottom>
                Interview Questions
            </Typography>

            {/* New Question Input */}
            <TextField
                label="Add a New Question"
                variant="outlined"
                fullWidth
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                style={{ marginBottom: '16px' }}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={handleAddQuestion}
            >
                Add Question
            </Button>

            <List>
                {questions.length > 0 ? (
                    questions.map((question, index) => (
                        <React.Fragment key={question.id}>
                            <ListItem
                                secondaryAction={
                                    <>
                                        <IconButton edge="end" onClick={() => handleEditClick(question)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton edge="end" onClick={() => handleDeleteQuestion(question.id)}>
                                            <Delete />
                                        </IconButton>
                                    </>
                                }
                            >
                                <ListItemText
                                    primary={`${index + 1}. ${question.question_text}`}
                                    primaryTypographyProps={{ variant: 'body1' }}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))
                ) : (
                    <ListItem>
                        <ListItemText primary="No questions available for this job." />
                    </ListItem>
                )}
            </List>

            {/* Edit Dialog */}
            <Dialog fullWidth maxWidth="md" open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={editingQuestion ? editingQuestion.question_text : ''}
                        onChange={(e) => setEditingQuestion({ ...editingQuestion, id: editingQuestion ? editingQuestion?.id : 0, question_text: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditQuestion} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default JobQuestionsList;
