import { createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6a1b9a', // Customize this color to your theme color
    },
    secondary: {
      main: '#ff5722', // Customize this color to your theme color
    },
  },
});

export default theme