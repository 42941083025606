import React, { useEffect, useState } from 'react';
import { fetchJobs, Job } from '../api/api';
import JobListItem from '../components/JobListItem';
import Layout from '../components/Layout';

const JobList: React.FC = () => {
    const [jobs, setJobs] = useState<Job[]>([]);

    useEffect(() => {
        const loadJobs = async () => {
            try {
                const jobsData = await fetchJobs();
                setJobs(jobsData);
            } catch (err) {
                console.error(err);
            }
        };

        loadJobs();
    }, []);

    return (
        <Layout>
            <div>
                <h2>Job Listings</h2>
                <ul>
                    {jobs.length > 0 ? (
                        jobs.map((job) => <JobListItem key={job.id} job={job} />)
                    ) : (
                        <p>No jobs available.</p>
                    )}
                </ul>
            </div>
        </Layout>
    );
};

export default JobList;
