import React, { ReactNode } from 'react';
import { AppBar, Toolbar, Typography, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <AppBar position="static">
                <Toolbar style={{backgroundColor:"purple"}}>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Admin Panel
                    </Typography>
                    {/* <Button color="inherit" component={Link} to="/categories">Categories</Button>
                    <Button color="inherit" component={Link} to="/questions">Questions</Button> */}
                    <Button color="inherit" component={Link} to="/users">Users</Button>
                    <Button color="inherit" component={Link} to="/jobs">Jobs</Button>
                    <Button color="inherit" component={Link} to="/companies">Companies</Button>
                    <Button color="inherit" component={Link} to="/companies/register">Register Company</Button>
                    
                </Toolbar>
            </AppBar>
            <Container>
                {children}
            </Container>
        </>
    );
};

export default Layout;
