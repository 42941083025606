import React, { useEffect, useState } from 'react';
import { fetchCompanies } from '../api/api';
import Layout from '../components/Layout';
import CompanyCard, { Company } from '../components/CompanyCard';
import { Box, Grid, Typography } from '@mui/material';

const CompanyList: React.FC = () => {
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadCompanies = async () => {
            try {
                const companies = await fetchCompanies();
                setCompanies(companies);
            } catch (error) {
                setError('Failed to load companies');
            } finally {
                setLoading(false);
            }
        };

        loadCompanies();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Layout>
            <Box sx={{ p: 2 }}>
                {companies.length > 0 ? (
                    <Grid container spacing={2}>
                        {companies.map((company) => (
                            <Grid item xs={12} sm={6} md={4} key={company.id}>
                                <CompanyCard
                                    company={company}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography>No companies registered yet.</Typography>
                )}
            </Box>
        </Layout>
    );
};

export default CompanyList;
