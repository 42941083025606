import React from 'react';
import { Box, Typography, Paper, Divider, Chip, Rating } from '@mui/material';
import { ResponseEvaluationDetail, UserResponse } from '../api/api';

interface ResponseItemProps {
    response: UserResponse;
    userID: string;
    jobID: string;
    evaluation?: ResponseEvaluationDetail
    questionID: string;
}

const getQuestionSound = (userID: string, jobID: string, soundID: string, questionID: string) => {
    //     const outputPath = path.join(__dirname, 'helper/storage', String(jobId),"interview",String(userId),String(questionId),uuid+".wav");
    //https://storage.googleapis.com/optihire-bucket-42/42-9-8e1f8dc7-8f54-4c55-956f-bc9cb9c97570
    return `https://storage.googleapis.com/optihire-bucket-42/${jobID}-${userID}-${soundID}`;
};

const ResponseItem: React.FC<ResponseItemProps> = ({ response, userID, jobID, evaluation, questionID }) => {
    return (
        <Paper elevation={2} sx={{ padding: 4, mb: 3, borderRadius: 3, backgroundColor: '#f5f5f5' }}>
            <Box display="flex" flexDirection="column">
                {/* Question */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.dark', mb: 2 }}>
                    {response.question_text}
                </Typography>

                {/* User Response */}
                <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic', mb: 3 }}>
                    {response.response_text}
                </Typography>

                {/* Divider */}
                <Divider sx={{ my: 2 }} />

                {/* AI Evaluation */}
                {evaluation && (
                    <Box sx={{ backgroundColor: '#fafafa', padding: 2, borderRadius: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'secondary.dark', mb: 2 }}>
                            AI Feedback
                        </Typography>
                        {/* AI Comments */}
                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                            {evaluation.comments}
                        </Typography>

                        {/* Evaluation Tags */}
                        <Box display="flex" gap={1} alignItems="center" sx={{ mb: 2 }}>
                            <Chip
                                label={`Quality: ${evaluation.quality}`}
                                color={evaluation.quality > 3 ? "success" : "warning"}
                                size="small"
                            />
                            <Chip
                                label={`Relevance: ${evaluation.relevance}`}
                                color={evaluation.relevance > 3 ? "success" : "warning"}
                                size="small"
                            />
                        </Box>

                        {/* Optional Rating Component */}
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                AI Rating:
                            </Typography>
                            <Rating
                                value={evaluation.quality / 2}
                                precision={0.5}
                                readOnly
                                sx={{ color: evaluation.quality > 3 ? 'success.main' : 'warning.main' }}
                            />
                        </Box>
                    </Box>
                )}

                {/* Audio Recording */}
                {response.response_sound && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.primary' }}>
                            Audio Recording of the Answer:
                        </Typography>
                        <audio controls aria-label="Audio Recording of the Answer" style={{ width: '100%' }}>
                            <source src={getQuestionSound(userID, jobID, response.response_sound, questionID)} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>
                )}

                {/* Divider between Main and Follow-up */}
                <Divider sx={{ my: 3 }} />

                {/* Follow-up Question */}
                {response.followup_question && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.dark', mb: 2 }}>
                            Follow-up Question
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic', mb: 3 }}>
                            {response.followup_question}
                        </Typography>

                        {/* Follow-up Answer */}
                        {response.followup_answer && (
                            <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic', mb: 3 }}>
                                {response.followup_answer}
                            </Typography>
                        )}

                        {/* Follow-up Audio */}
                        {response.followup_sound && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.primary' }}>
                                    Audio Recording of the Follow-up Answer:
                                </Typography>
                                <audio controls aria-label="Audio Recording of the Follow-up Answer" style={{ width: '100%' }}>
                                    <source src={getQuestionSound(userID, jobID, response.followup_sound, questionID)} type="audio/wav" />
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default ResponseItem;
