import React, { useEffect, useState } from 'react';
import { addJob, Company, fetchCompanyById } from '../api/api';
import { Button, TextField, Container, Typography, Alert, CircularProgress, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';

const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    { code: 'fr', label: 'Français' },
    { code: 'de', label: 'Deutsch' },
    { code: 'tr', label: 'Turkish' },
    { code: 'hu', label: 'Hungarian' }, 
    { code: 'ar', label: 'Arabic' },    
];

const JobUpload: React.FC = () => {
    const { companyId } = useParams<{ companyId: string }>();
    const [jobTitle, setJobTitle] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [location, setLocation] = useState('');
    const [salary, setSalary] = useState('');

    const [error, setError] = useState<string | null>(null);
    const [company, setCompany] = useState<Company | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [language, setLanguage] = useState('en'); // Default language

    const handleChange = (event:SelectChangeEvent) => {
        setLanguage(event.target.value);
        // Add logic to handle language change in your app, e.g., by calling a function to switch the language.
    };
    useEffect(() => {
        const loadCompany = async () => {
            try {
                const companyData = await fetchCompanyById(Number(companyId));
                setCompany(companyData);
            } catch (error) {
                setError('Failed to load company details or jobs');
            } finally {
                setLoading(false);
            }
        };

        loadCompany();
    }, [companyId]);


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (companyId !== undefined) {
                const selectedLang = languages.find((val)=>val.code === language)?.label
                const job = await addJob(jobTitle, Number(companyId), description, requirements, location, salary,selectedLang);
                navigate(`/jobs/${job.id}`); // Navigate to the job page
            } else {
                alert('Company ID is required');
            }
        } catch (err) {
            console.error(err);
            setError('Failed to add job');
        }
    };
    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Add Job for Company {company?.name}
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Job Title"
                        variant="outlined"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <TextField
                        fullWidth
                        label="Requirements"
                        variant="outlined"
                        value={requirements}
                        onChange={(e) => setRequirements(e.target.value)}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <TextField
                        fullWidth
                        label="Location"
                        variant="outlined"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Salary"
                        variant="outlined"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        margin="normal"
                    />
                    <InputLabel>Language</InputLabel>
                    <Select
                        value={language}
                        onChange={handleChange}
                        label="Language"
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {lang.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
                        Add Job
                    </Button>
                </form>
            </Container>
        </Layout>
    );
};

export default JobUpload;
