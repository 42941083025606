import React from 'react';
import { Card, CardContent, Typography, Grid, Divider } from '@mui/material'; // Import MUI components
import { Answer } from '../api/api';

const getQuestionSound = (userID: string, jobId: string, soundID: string, type: string) => {
    return `http://localhost:3100/audio/${userID}/session/${jobId}/${type}-${soundID}.wav`;
};

// Reusable component for rendering audio elements
const AudioPlayer: React.FC<{ soundUrl: string; label: string }> = ({ soundUrl, label }) => {
    return (
        <Card variant="outlined" style={{ marginBottom: '16px' }}>
            <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                    {label}
                </Typography>
                <audio controls style={{ width: '100%' }}>
                    <source src={soundUrl} type="audio/wav" />
                    Your browser does not support the audio element.
                </audio>
            </CardContent>
        </Card>
    );
};

// Reusable component for rendering question and response media
const QuestionResponseMedia: React.FC<{
    user_id: string;
    category_id: string;
    soundID?: string;
    text?: string
    type: 'ai' | 'human';
}> = ({ user_id, category_id, text, soundID, type }) => {
    if (!soundID) return null;

    const soundUrl = getQuestionSound(user_id, category_id, soundID, type);
    const label = type === 'ai' ? 'AI response Confirmation' : 'User Response';

    return <AudioPlayer soundUrl={soundUrl} label={text ? text : label} />;
};

const ListItem: React.FC<Answer> = ({
    question_id,
    question_text,
    answer,
    category_name,
    user_id,
    category_id,
    answer_sound,
    uuid,
}) => {
    return (
        <Card color="#fff" variant="outlined" style={{ marginBottom: '24px' }}>
            <CardContent>


                <Typography variant="body1">
                    <strong>Question {question_id}:</strong> {question_text}
                </Typography>
                <Typography variant="body1" style={{ marginTop: '24px' }}>
                    <strong>Answer:</strong> {answer}
                </Typography>
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                    Question Media
                </Typography>
                <Divider style={{ marginBottom: '16px' }} />

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <QuestionResponseMedia
                            user_id={user_id}
                            category_id={String(category_id)}
                            soundID={answer_sound}
                            type="human"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <QuestionResponseMedia
                            user_id={user_id}
                            category_id={String(category_id)}
                            soundID={answer_sound}
                            type="ai"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      
                        <QuestionResponseMedia
                            user_id={user_id}
                            category_id={String(category_id)}
                            soundID={uuid}
                            text="Users answer confirmation"
                            type="human"
                        />
                    </Grid>
                </Grid>

 

            </CardContent>
        </Card>
    );
};

export default ListItem;
