// src/pages/UserAnswers.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Answer, fetchUserAnswers, fetchUserDetails, User } from '../api/api';
import ListItem from '../components/QuestionListItem';
import '../styles/UserAnswers.css'; // Import the CSS file
import Layout from '../components/Layout';

const groupByCategory = (answers: Answer[]) => {
    return answers.reduce((groups, answer) => {
        const category = answer.category_name;
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(answer);
        return groups;
    }, {} as Record<string, Answer[]>);
};

const UserAnswers: React.FC = () => {
    const { userId,categoryId } = useParams<{ userId: string,categoryId:string }>();
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useState<User>();

    useEffect(() => {
        const loadAnswers = async () => {
            try {
                if (userId) {
                    const answers = await fetchUserAnswers(Number(userId),Number(categoryId));
                    setAnswers(answers);
                }
            } catch (error) {
                setError('Failed to load answers');
            } finally {
                setLoading(false);
                const userName = await fetchUserDetails(Number(userId));
                setUser(userName);
            }
        };

        loadAnswers();
    }, [userId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const groupedAnswers = groupByCategory(answers);

    return (
        <Layout>
            
                <h1  style={{ color: '#000' }}>User {user?.username}</h1>
                {Object.keys(groupedAnswers).length > 0 ? (
                    Object.entries(groupedAnswers).map(([categoryName, categoryAnswers]) => (
                        <div key={categoryName} className="category-card">
                            <h2>Category: {categoryName}</h2>
                            <ul>
                                {categoryAnswers.map((answer, index) => (
                                    <ListItem
                                        key={index}
                                        answer_sound={answer.answer_sound}
                                        category_id={answer.category_id}
                                        uuid={answer.uuid}
                                        user_id={answer.user_id}
                                        question_id={answer.question_id}
                                        question_text={answer.question_text}
                                        answer={answer.answer}
                                        category_name={answer.category_name}
                                    />
                                ))}
                            </ul>
                        </div>
                    ))
                ) : (
                    <p>No answers available for this user.</p>
                )}
           
        </Layout>
    );
};

export default UserAnswers;
