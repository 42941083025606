import React, { useState } from 'react';
import { addCategory } from '../api/api';
import '../styles/CategoryStyles.css';
import { Button,  Typography, FormControl, InputLabel, Input, FormHelperText } from '@mui/material';

const AddCategoryForm: React.FC = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await addCategory(name, description, parseInt(orderNumber, 10));
            setSuccess('Category added successfully!');
            setError(null);
            setName('');
            setDescription('');
            setOrderNumber('');
        } catch (err) {
            setError('Failed to add category');
            setSuccess(null);
        }
    };

    return (
        <div className="add-category-form">
             <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <FormHelperText>Enter the category name</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="description">Description</InputLabel>
                    <Input
                        id="description"
                        type="text"
                        multiline
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                    <FormHelperText>Provide a brief description</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="orderNumber">Order Number</InputLabel>
                    <Input
                        id="orderNumber"
                        type="number"
                        value={orderNumber}
                        onChange={(e) => setOrderNumber(e.target.value)}
                        required
                    />
                    <FormHelperText>Specify the order number</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 20 }}
                >
                    Add Category
                </Button>
                {error && <Typography color="error" style={{ marginTop: 10 }}>{error}</Typography>}
                {success && <Typography color="success" style={{ marginTop: 10 }}>{success}</Typography>}
            </form>
        </div>
    );
};

export default AddCategoryForm;
