import "../styles/CompanyRegistration.css";
import React, { useState } from 'react';
import { registerCompany } from '../api/api';
import Layout from "../components/Layout";
import { TextField, Button, Typography, Grid, Card, CardContent, InputLabel, IconButton, Divider } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const CompanyRegistration: React.FC = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [sector, setSector] = useState('');
    const [numEmployees, setNumEmployees] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [type, setType] = useState('');
    const [creationDate, setCreationDate] = useState('');
    const [contactPersonName, setContactPersonName] = useState('');
    const [contactPersonPhone, setContactPersonPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [bannerFile, setBannerFile] = useState<File | null>(null);
    const [iconFile, setIconFile] = useState<File | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        try {
            // Assuming registerCompany handles file uploads and other data
            const company = await registerCompany({
                name,
                description,
                location,
                website_url:websiteUrl,
                sector,
                num_employees: numEmployees,
                contact_info: contactInfo,
                type,
                creation_date: creationDate,
                contact_person_name: contactPersonName,
                contact_person_phone: contactPersonPhone,
                email,
                password,
                banner_file: bannerFile,
                icon_file: iconFile,
            });

            setSuccessMessage(`Company "${company.name}" registered successfully!`);
            setErrorMessage(null);
        } catch (error) {
            setErrorMessage('Failed to register company');
            setSuccessMessage(null);
        }
    };

    return (
        <Layout>
            <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
                <CardContent>
                    <Typography variant="h4" component="div" gutterBottom>
                        Register Company
                    </Typography>
                    {successMessage && <Typography color="green">{successMessage}</Typography>}
                    {errorMessage && <Typography color="red">{errorMessage}</Typography>}
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Company Name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Location"
                                    variant="outlined"
                                    fullWidth
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Website URL"
                                    variant="outlined"
                                    fullWidth
                                    value={websiteUrl}
                                    onChange={(e) => setWebsiteUrl(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Sector"
                                    variant="outlined"
                                    fullWidth
                                    value={sector}
                                    onChange={(e) => setSector(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Number of Employees"
                                    variant="outlined"
                                    fullWidth
                                    value={numEmployees}
                                    onChange={(e) => setNumEmployees(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Company Type"
                                    variant="outlined"
                                    fullWidth
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Creation Date"
                                    variant="outlined"
                                    fullWidth
                                    value={creationDate}
                                    onChange={(e) => setCreationDate(e.target.value)}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Contact Info"
                                    variant="outlined"
                                    fullWidth
                                    value={contactInfo}
                                    onChange={(e) => setContactInfo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Contact Person Name"
                                    variant="outlined"
                                    fullWidth
                                    value={contactPersonName}
                                    onChange={(e) => setContactPersonName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Contact Person Phone"
                                    variant="outlined"
                                    fullWidth
                                    value={contactPersonPhone}
                                    onChange={(e) => setContactPersonPhone(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    type="password"
                                    required
                                />
                            </Grid>
                          

                            <Grid item xs={12} sm={6}>
                                <InputLabel htmlFor="banner-upload">Banner Image</InputLabel>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    fullWidth
                                >
                                    Upload Banner
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(e) => handleFileUpload(e, setBannerFile)}
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel htmlFor="icon-upload">Icon Image</InputLabel>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<PhotoCamera />}
                                    fullWidth
                                >
                                    Upload Icon
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(e) => handleFileUpload(e, setIconFile)}
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleRegister}
                                    sx={{
                                        textTransform: 'none',
                                        borderRadius: '20px',
                                        bgcolor: 'purple',
                                        '&:hover': {
                                            bgcolor: 'darkpurple',
                                        },
                                    }}
                                >
                                    Register Company
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Layout>
    );
};

export default CompanyRegistration;
