import React from 'react';
import Layout from '../components/Layout';
import CategoryList from '../components/CategoryList';

const Categories: React.FC = () => {
    return (
        <Layout>
            <CategoryList />
        </Layout>
    );
};

export default Categories;
