import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { Category, fetchCategories } from '../api/api';
import AddCategoryForm from './AddCategoryForm';

const CategoryList: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        const loadCategories = async () => {
            const categories = await fetchCategories();
            setCategories(categories);
        };

        loadCategories();
    }, []);

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'purple', textAlign: 'center' }}>
                Categories
            </Typography>
            <Grid container spacing={4}>
                {/* Categories Column */}
                <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={3}>
                        {categories.map((category) => (
                            <Grid item key={category.category_id}>
                                <Card
                                    sx={{
                                        borderRadius: '12px',
                                        border: '1px solid #ddd',
                                        '&:hover': { boxShadow: 4 },
                                        minHeight: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            {category.name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                            {category.description}
                                        </Typography>
                                        <Button
                                            component={Link}
                                            to={`/categories/${category.category_id}/${category.name}/questions`}
                                            variant="contained"
                                            color="primary"
                                            sx={{ textTransform: 'none', borderRadius: '20px', mt: 'auto' }}
                                        >
                                            View Questions
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Add New Category Form Column */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ borderRadius: '12px', border: '1px solid #ddd', padding: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: 'purple', textAlign: 'center' }}>
                            Add New Category
                        </Typography>
                        <AddCategoryForm />
                    </Card>
                </Grid>
            </Grid>
            <span /> 
            <br />
        </Container>
    );
};

export default CategoryList;
