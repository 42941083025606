import React, { useEffect, useState } from 'react';
import {
    Typography,
    List,
    Divider,
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Box,
    Alert
} from '@mui/material';
import ResponseItem from './ResponseItem';
import axios from 'axios';
import { createAudioForInterview, CvEvaluation, fetchEvaluations, fetchIntervieweesOfUserWithJobId, fetchUserDetails, ResponseEvaluation, User, UserResponse } from '../api/api';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import EvaluationSummary from './EvaluationSummary';
import UserInfo from './UserInfo';
import CVEvaluation from './CVEvaluation';

// Define the evaluation interfaces


export interface Evaluation {
    response_evaluation: ResponseEvaluation;
    cv_evaluation: CvEvaluation;
    overall_score: number;
    recommendations: string;
}



interface Interviewee {
    user_id: number;
    user_name: string;
    cv_evaluation?: CvEvaluation;
    responses: UserResponse[];
}

// Component
const IntervieweeItem: React.FC = () => {
    const { jobID, userID } = useParams<{ userID: string, jobID: string }>();
    const [user, setUser] = useState<User | null>(null);
    const [interviewee, setInterviewee] = useState<Interviewee | undefined>(undefined);
    const [evaluation, setEvaluation] = useState<Evaluation | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchInterviewees = async () => {
            try {
                const response = await fetchIntervieweesOfUserWithJobId(Number(jobID), Number(userID));
                const interviewData = response[0]
                if (interviewData) {
                    setInterviewee(interviewData);
                } else {
                    // setError('No interview data found.');
                }
            } catch (err) {
                console.error('Error fetching interviewees:', err);
                // setError('Failed to fetch interview data.');
            }
        };
        fetchInterviewees();
    }, [jobID, userID]);

    // Fetch user details
    useEffect(() => {
        const getUserDetails = async () => {
            try {
                const res = await fetchUserDetails(Number(userID));
                setUser(res);
            } catch (err) {
                console.error('Error fetching user details:', err);
                //setError('Failed to fetch user details.');
            }
        };
        getUserDetails();
    }, [userID]);

    // Fetch evaluation data
    useEffect(() => {
        const fetchEvaluation = async () => {
            try {
                if (jobID === undefined || userID === undefined) return
                const response = await fetchEvaluations(jobID, userID)
                setEvaluation(response);
            } catch (err) {
                console.error('Error fetching evaluation:', err);
                //setError('Failed to fetch evaluation data.');
            } finally {
                setLoading(false);
            }
        };
        fetchEvaluation();
    }, [jobID, userID]);





    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error" sx={{ mb: 4 }}>
                        {error}
                    </Alert>
                ) : (
                    <>
                        {/* User Information */}
                        {user && <UserInfo user={user} />}
                        {
                            interviewee?.cv_evaluation && <CVEvaluation evaluation={interviewee.cv_evaluation} />
                        }
                        {evaluation && <EvaluationSummary evaluation={evaluation} />}

                        {console.log("responses", interviewee?.responses)}
                        {/* Responses Section */}
                        <Paper elevation={3} sx={{ padding: 4, mb: 4, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Interview Responses
                            </Typography>
                            <List>
                                {interviewee?.responses.map((response, index) => (
                                    <Box key={index}>
                                        <ResponseItem
                                            response={response}
                                            userID={String(userID)}
                                            jobID={String(jobID)}
                                            questionID={response.questionID}
                                            evaluation={evaluation?.response_evaluation.details[response.questionID]}
                                        />
                                        {index < (interviewee.responses.length - 1) && <Divider />}
                                    </Box>
                                ))}
                            </List>
                        </Paper>

                    </>
                )}
            </Container>
        </Layout>
    );
};

export default IntervieweeItem;
