//import "../styles/JobApplication.css";
import React, { FormEventHandler, useState } from 'react';
import { TextField, Button, Typography, Grid, Card, CardContent, InputLabel } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { applyForJob } from '../api/api';
import { useParams } from 'react-router-dom';

const JobApplication = () => {
    const { jobID } = useParams<{ jobID: string }>();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        coverLetter: '',
        salary:"",
        location:"",
    });
    const [cv, setcv] = useState<File>()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setcv(e.target.files[0]);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const data = new FormData();

        // Append text fields
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('salary', formData.salary);
        data.append('location', formData.location);
        data.append('cover_letter', formData.coverLetter);
        console.log(cv)
        if (cv)
            data.append('cv', cv);
        else 
            return 

        applyForJob(Number(jobID), data).then((val)=>{
            alert("Application submitted successfully!");
        })
    };

    return (
        <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
            <CardContent>
                <Typography variant="h4" component="div" gutterBottom>
                    Job Application
                </Typography>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Full Name"
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email Address"
                                variant="outlined"
                                fullWidth
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Salary"
                                variant="outlined"
                                fullWidth
                                name="salary"
                                value={formData.salary}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Location"
                                variant="outlined"
                                fullWidth
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Cover Letter"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                name="coverLetter"
                                value={formData.coverLetter}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="resume-upload">Upload Resume</InputLabel>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCamera />}
                                fullWidth
                            >
                                Upload Resume
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '20px',
                                    bgcolor: 'purple',
                                    '&:hover': {
                                        bgcolor: 'darkpurple',
                                    },
                                }}
                            >
                                Submit Application
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};

export default JobApplication;
