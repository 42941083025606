import axios from 'axios';
//const API_URL = 'http://localhost:8080/api';
const backendUrl = "https://ai.optihireai.com/";

//const backendUrl = "http://localhost:3200/";

const API_URL =  'https://api.optihireai.com/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const backendApi = axios.create({
    baseURL: backendUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});


export interface Category {
    category_id: number;
    name: string;
    description: string;
}
export const fetchCategories = async () => {
    const response = await api.get('/categories');
    return response.data.categories;
};

// Fetch questions with optional categoryId
export const fetchQuestions = async (categoryId?: number) => {
    const url = categoryId ? `/categories/${categoryId}/questions` : '/questions';
    const response = await api.get(url);
    return response.data.questions;
};


export const fetchUsers = async () => {
    const response = await api.get('/users');
    return response.data.users;
};

export interface User {
    username: string
    email?: string
    role?: string;
    birth_date?: string;
    location?: string;
    cv_url?: string
}

export const fetchUserDetails = async (userId: number): Promise<User> => {
    const response = await api.get(`/users/${userId}`);
    const data = await response.data
    return data;
};

export interface JobInfo {

}

export const fetchUserJobs = async (userId: number): Promise<Job[]> => {
    const response = await api.get(`/users/${userId}/jobs`);
    const data = await response.data
    return data;
};


export const addCategory = async (name: string, description: string, orderNumber: number) => {
    const response = await api.post('/categories', { name, description, orderNumber });
    return response.data;
};


export const addQuestion = async (categoryId: number, questionText: string) => {
    const response = await api.post(`/categories/${categoryId}/questions`, { questionText });
    return response.data;
};

export interface Answer {
    question_id: number;
    question_text: string;
    answer: string;
    category_name: string;
    category_id: number;
    uuid?: string;
    user_id: string;
    answer_sound?: string;
}


export const fetchUserAnswers = async (userId: number, categoryId: number): Promise<Answer[]> => {
    try {
        const response = await api.get(`/users/${userId}/answers/${categoryId}`);
        return response.data.answers;
    } catch (error) {
        console.error('Error fetching user answers:', error);
        throw error; // Rethrow to handle it in the component or wherever it's used
    }
};


export const registerCompany = async (company: {
    name: string,
    description: string,
    location: string,
    website_url: string,
    sector: string,
    num_employees: string,
    contact_info: string,
    type: string,
    creation_date: string,
    contact_person_name: string,
    contact_person_phone: string,
    email: string,
    password: string,
    banner_file: File | null,
    icon_file: File | null
}) => {
    const formData = new FormData();

    // Append text fields
    formData.append('name', company.name);
    formData.append('description', company.description);
    formData.append('location', company.location);
    formData.append('websiteUrl', company.website_url);
    formData.append('sector', company.sector);
    formData.append('numEmployees', company.num_employees);
    formData.append('contactInfo', company.contact_info);
    formData.append('type', company.type);
    formData.append('creationDate', company.creation_date);
    formData.append('contactPersonName', company.contact_person_name);
    formData.append('contactPersonPhone', company.contact_person_phone);
    formData.append('email', company.email);
    formData.append('password', company.password);

    // Append files if they exist
    if (company.banner_file) {
        formData.append('bannerFile', company.banner_file);
    }
    if (company.icon_file) {
        formData.append('iconFile', company.icon_file);
    }

    // Send the request
    const response = await api.post('/companies', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data.company;
};

// export const registerCompany = async (company: {
//     name:string,
//     description:string,
//     location:string,
//     website_url:string,
//     sector:string,
//     num_employees:string,
//     contact_info:string,
//     type:string,
//     creation_date:string,
//     contact_person_name:string,
//     contact_person_phone:string,
//     email:string,
//     password:string,
//     banner_file:File | null,
//     icon_file:File | null   
// }) => {
//     const response = await api.post('/companies', company);
//     return response.data.company;
// };

export const fetchCompanies = async () => {
    const response = await api.get('/companies');
    return response.data.companies;
};

export const fetchCompanyById = async (companyId: number): Promise<Company> => {
    const response = await api.get(`/companies/${companyId}`);
    console.log(response.data)
    return response.data.company;
};



export const updateCompany = async (companyId: number, company: {
    name: string;
    description: string;
    location: string;
    websiteUrl: string;
}) => {
    const response = await api.put(`/companies/${companyId}`, company);
    return response.data.company;
};


export const deleteCompany = async (companyId: number) => {
    const response = await api.get(`/companies/${companyId}`);
    return response.data.company;
};


export interface Company {
    id: number;
    name: string;
    description: string;
    location: string;
    website_url: string;
    banner_url?: string;
    icon_url?: string;
    sector?: string;
    num_employees?: string;
    contact_info?: string;
    type?: string;
    creation_date?: string;
}

// Define the Job interface
export interface Job {
    id: number;
    job_title: string;
    description: string;
    requirements: string;
    name: string;
    location?: string;
    salary?: string;
    posted_at: string;
    updated_at: string;
    company_id: number;
    company_name: string;
}

// Fetch job details by ID
export const fetchJobById = async (jobId: number): Promise<Job> => {
    const response = await api.get<{ job: Job }>(`/jobs/${jobId}`);
    return response.data.job;
};

// Fetch jobs by company ID
export const fetchJobsByCompany = async (companyId: number): Promise<Job[]> => {
    const response = await api.get<{ jobs: Job[] }>(`/jobs/company/${companyId}`);
    return response.data.jobs;
};


export interface JobKeyword {
    id: number;
    job_id: number;
    keyword: string;
}

// Fetch job keywords by job ID
export const fetchJobKeywordsById = async (jobId: number): Promise<JobKeyword[]> => {
    const response = await api.get<JobKeyword[]>(`/job-keywords/${jobId}`);
    return response.data;
};

export interface JobRequirement {
    id: number;
    job_id: number;
    requirement: string;
    created_at: string;
}

// Fetch job requirements by job ID
export const fetchJobRequirementsById = async (jobId: number): Promise<JobRequirement[]> => {

    const response = await api.get<JobRequirement[]>(`/job-requirements/${jobId}`);
    return response.data
};


export const updateUserProfile = async (userID: number, userData: Partial<User>): Promise<User> => {
    const response = await api.put(`/users/${userID}`, userData);
    return response.data;
};

// Upload CV file
export const uploadCv = async (userID: number, cvFile: File): Promise<void> => {
    const formData = new FormData();
    formData.append('cv', cvFile);

    await api.post(`/users/${userID}/upload-cv`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};


export interface ResponseEvaluationDetail {
    relevance: number;
    quality: number;
    comments: string;
}

export interface ResponseEvaluation {
    details: {
        [key: string]: ResponseEvaluationDetail;
    };
    score: number;
}

export interface CvEvaluation {
    qualifications: { score: number, comments: string };
    experience: { score: number, comments: string };
    skills: { score: number, comments: string };
    overall: { score: number, comments: string };
    comments: string;
}

export interface UserResponse {
    question_text: string;
    response_text: string;
    response_sound?: string;
    questionID: string;
    followup_question?: string;
    followup_answer?: string;
    followup_sound?: string
}

export interface Interviewee {
    user_id: number;
    user_name: string;
    responses: UserResponse[];
    cv_url?: string; // CV URL is optional
    score?: number;
    completed?: boolean;
    cv_evaluation?: CvEvaluation;

}



export interface IntervieweeEvaluation {
    user_id: number;
    user_name: string;
    cv_url?: string; // CV URL is optional
    score?: number;
    phone: string,
    location: string,
    salary: string,
    email: string,
    completed?: boolean;
    cv_evaluation?: CvEvaluation;
    response_evaluation?: ResponseEvaluation;
    overall_score?: number
    recommendations?: string,
}


export const fetchIntervieweesWithJobId = async (jobId: number): Promise<Interviewee[]> => {
    const response = await api.get(`/jobs/${jobId}/interviewees`);
    return response.data
};

export interface Applicant {
    id: number;
    name: string;
    email: string;
    resume: string;
    applied_at: string;
    cover_letter: string;
    phone: string;
    salary: string;
    location: string;
    status?: string;
    cv_evaluation: EvaluationCV,
    score: string,
}


interface EvaluationCV {
    qualifications: Qualifications;
    experience: Qualifications;
    skills: Qualifications;
    relevance: Qualifications;
    overall: Qualifications;
    summary: string;
    comments: string;
}

interface Qualifications {
    score: number;
    comments: string;
}

export const fetchApplicantsWithJobId = async (jobId: number): Promise<Applicant[]> => {
    const response = await api.get(`/jobs/${jobId}/applicants`);
    return response.data
};


export const fetchIntervieweesEvaluationsWithJobId = async (jobId: number): Promise<IntervieweeEvaluation[]> => {
    const response = await api.get(`/jobs/${jobId}/interviewees/evaluations`);
    return response.data
};


export const fetchIntervieweesOfUserWithJobId = async (jobId: number, userId: number): Promise<Interviewee[]> => {
    const response = await api.get(`/jobs/${jobId}/interviewees/${userId}`);

    return response.data
};

export interface JobSummary {
    id: number;
    job_title: string;
    company_name: string;
}


// Fetch all jobs
export const fetchJobs = async (): Promise<Job[]> => {
    const response = await api.get<{ jobs: Job[] }>('/jobs');
    return response.data.jobs;
};

export interface JobQuestion {
    id: number;
    job_id: number;
    question_text: string;
}

export const fetchJobQuestions = async (jobId: number): Promise<JobQuestion[]> => {
    const response = await api.get(`/job-questions/${jobId}`);
    return response.data;
};

export const applyForJob = async (jobId: number, formData: FormData) => {
    const response = await api.post(`/jobs/${jobId}/apply`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};


export const createAudioForInterview = async (userId: number, jobId: number,) => {
    const response = await backendApi.post('/create-audio', {
        userId,
        jobId
    });
    return response.data;
};


export const ScoreInterview = async (jobId: number, userId: number, pdfUrl: string) => {
    const response = await backendApi.post('/score-interview', {
        jobId,
        userId,
        pdfUrl,
    });
    return response.data;
};

export const fetchEvaluations = async (jobID: string, userID: string) => {
    try {
        const response = await api.get(`/evaluation`, {
            params: {
                job_id: jobID,
                user_id: userID
            }
        });
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error fetching evaluation:', error);
        throw error;
    }
}


const generateJobQuestions = async (jobId: number, jobDescription: string, title: string, companyId: number, language?: string) => {
    const company = await fetchCompanyById(companyId)

    const requestBody = {
        jobId: jobId,
        jobDescription: jobDescription,
        title,
        company_description: company.description,
        company,
        language
    };

    try {
        const response = await backendApi.post('/generate-job-questions', requestBody);
        return response.data;
    } catch (error) {
        console.error('Error generating job questions:', error);
        throw error;
    }
};



export const submitJobQuestions = async (job_id: number, questions: [{ question: string, relevance: string }]): Promise<void> => {
    await api.post(`/job-questions`, { job_id, questions })
};



export const UpdateJobQuestions = async (id: number, question_text: string): Promise<void> => {
    await api.put(`/job-questions/` + id, { question_text })
};


export const deleteJobQuestions = async (id: number): Promise<void> => {
    await api.delete(`/job-questions/` + id)
};

export const addJob = async (
    jobTitle: string,
    companyId: number,
    description: string,
    requirements: string,
    location?: string,
    salary?: string,
    language?: string,
): Promise<Job> => {
    const response = await api.post<{ job: Job }>('/jobs', {
        jobTitle,
        companyId,
        description,
        requirements,
        location,
        salary,
        language
    });
    const { id } = response.data.job
    generateJobQuestions(id, description, jobTitle, companyId, language)
    return response.data.job;
};