import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { LocationOn, Web, Info, Business } from '@mui/icons-material';

export interface Company {
    id: number;
    name: string;
    description: string;
    location: string;
    website_url: string;
    banner_url?: string;
    icon_url?: string;
    sector?: string;
    num_employees?: string;
    contact_info?: string;
    type?: string;
    creation_date?: string;
}

interface CompanyCardProps {
    company: Company;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
    const onClick = (id: number) => {
        window.location.href = `/companies/${id}`
    }

    return (
        <Card
            variant="outlined"
            sx={{ borderRadius: 2, cursor: 'pointer', boxShadow: 3, height: '100%' }}
            onClick={() => onClick(company.id)}
        >
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box
                        sx={{
                            width: 80,
                            height: 80,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            mr: 2,
                        }}
                    >
                        <img
                            src={company.icon_url || `https://picsum.photos/80/80?random=${company.id}`}
                            alt={`${company.name} Icon`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                            {company.name}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            {company.description.substring(0, 100)}{company.description.length > 100 ? '...' : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="body2" color="textSecondary">
                            {company.location}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Web sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="body2" color="textSecondary">
                            {company.website_url}
                        </Typography>
                    </Box>
                    {company.sector && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Business sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2" color="textSecondary">
                                {company.sector}
                            </Typography>
                        </Box>
                    )}
                    {company.num_employees && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Info sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2" color="textSecondary">
                                {company.num_employees} employees
                            </Typography>
                        </Box>
                    )}
                    {company.contact_info && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Info sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="body2" color="textSecondary">
                                {company.contact_info}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default CompanyCard;
