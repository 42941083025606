import React from 'react';
import { useParams } from 'react-router-dom';
import QuestionList from '../components/QuestionList'; // Import the QuestionList component
import Layout from '../components/Layout';
import QuestionForm from '../components/QuestionForm';

const Questions: React.FC = () => {
    const { categoryId, category } = useParams<{ categoryId?: string, category: string }>();


    return (
        <Layout>
            <br></br>

            {
                categoryId && <QuestionForm categoryId={parseInt(categoryId)} />
            }
            <br></br>

            <QuestionList category={category ? category : ""} categoryId={categoryId ? parseInt(categoryId, 10) : undefined} />


        </Layout>
    );
};

export default Questions;
