import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { LocationOn, AttachMoney, DateRange, Work } from '@mui/icons-material';
import { Job } from '../api/api';

interface JobListItemProps {
    job: Job;
}

const JobListItem: React.FC<JobListItemProps> = ({ job }) => {
    return (
        <Card variant="outlined" sx={{ mb: 3, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                {/* Title with Background */}
                <Box sx={{ backgroundColor: '#f5f5f5', borderRadius: 1, padding: 1, mb: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
                        {job.job_title}
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {/* Left Side - Icons */}
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {/* Company Name */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Work sx={{ mr: 1, color: '#00796b' }} />
                                <Typography color="textSecondary" variant="body2">{job.name}</Typography>
                            </Box>
                            
                            {/* Location */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOn sx={{ mr: 1, color: '#0288d1' }} />
                                <Typography color="textSecondary" variant="body2">{job.location || 'Location not specified'}</Typography>
                            </Box>

                            {/* Salary */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AttachMoney sx={{ mr: 1, color: '#d32f2f' }} />
                                <Typography color="textSecondary" variant="body2">{job.salary || 'Salary not specified'}</Typography>
                            </Box>

                            {/* Posted Date */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <DateRange sx={{ mr: 1, color: '#f57c00' }} />
                                <Typography color="textSecondary" variant="body2">{`Posted on ${new Date(job.posted_at).toLocaleDateString()}`}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Right Side - Description */}
                    <Grid item xs={12} md={8}>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            {job.description.length > 400 ? (
                                <>
                                    {job.description.substring(0, 400) + "... "}
                                    
                                </>
                            ) : (
                                job.description || 'No description available'
                            )}
                        </Typography>
                        <Button
                            component={Link}
                            to={`/jobs/${job.id}`}
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: 'none' }}
                        >
                            View Job
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default JobListItem;
