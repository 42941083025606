import React from 'react';
import Layout from '../components/Layout';

const Dashboard: React.FC = () => {
    return (
        <Layout>
            <h2>Welcome to the Admin Panel</h2>
            <p>Select a category from the menu to get started.</p>
        </Layout>
    );
};

export default Dashboard;
