import React from 'react';
import { Paper, Typography, Box, Grid, LinearProgress, Divider } from '@mui/material';
import { Evaluation } from './IntervieweeItem';
import { CvEvaluation } from '../api/api';

interface EvaluationSummaryProps {
    evaluation: CvEvaluation;
}

const CVEvaluation: React.FC<EvaluationSummaryProps> = ({ evaluation }) => {
    const getProgressValue = (score: number) => (score / 10) * 100; // Convert score to percentage for progress bar

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                CV Evaluation Summary
            </Typography>

            {/* CV Evaluation */}
            <Box sx={{ mb: 3 }}>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Qualifications: {evaluation.qualifications.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.qualifications.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Experience: {evaluation.experience.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.experience.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Skills: {evaluation.skills.comments}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.skills.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Overall: {evaluation.overall.score}/10
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getProgressValue(evaluation.overall.score)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                            Comments: {evaluation.comments}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
 
        </Paper>
    );
};

export default CVEvaluation;
