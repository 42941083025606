import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography,
    Button,
    Card,
    CardContent,
    Box,
    Chip,
    Divider,
    CircularProgress,
    Alert,
    IconButton,
    ListItem,
    ListItemText
} from '@mui/material';
import { LocationOn, AttachMoney, Favorite } from '@mui/icons-material';
import { fetchJobById, applyForJob, fetchJobKeywordsById, JobKeyword, JobRequirement, fetchJobRequirementsById, fetchCompanyById } from '../api/api';
import JobQuestionsList from '../components/JobQuestionsList';
import Layout from '../components/Layout';

const JobDetail: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const navigate = useNavigate();
    const [job, setJob] = useState<any>(null);
    const [company, setCompany] = useState<any>(null); // Add state for company
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [keywords, setKeywords] = useState<JobKeyword[]>([]);
    const [requirements, setRequirements] = useState<JobRequirement[]>([]);

    useEffect(() => {
        const loadJobAndCompany = async () => {
            try {
                const jobData = await fetchJobById(Number(jobId));
                setJob(jobData);

                const jobKeywords = await fetchJobKeywordsById(Number(jobId));
                const jobRequirements = await fetchJobRequirementsById(Number(jobId));
                setKeywords(jobKeywords);
                setRequirements(jobRequirements);

                if (jobData.company_id) {
                    const companyData = await fetchCompanyById(jobData.company_id);
                    setCompany(companyData);
                }
            } catch (error) {
                //  setError('Failed to load job details');
            } finally {
                setLoading(false);
            }
        };

        loadJobAndCompany();
    }, [jobId]);

    const handleApply = async () => {
        navigate(`/apply/${jobId}`);

    };

    const handleViewInterviewees = () => {
        navigate(`/jobs/${jobId}/interviewees`);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            {job ? (
                <Box sx={{ p: 3 }}>
                    {/* Header Section */}
                    <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
                        <CardContent sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    mr: 3
                                }}
                            >
                                <img
                                    src={job.icon_url || `https://picsum.photos/80/80?random=${job.id}`}
                                    alt={`${job.company_name} Icon`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" gutterBottom>
                                    {job.job_title}
                                </Typography>
                                <Typography variant="h6" color="textSecondary" gutterBottom>
                                    {job.company_name}
                                </Typography>
                                {company && (
                                    <Box sx={{ mb: 1 }}>
                                        <Typography variant="body2" color="textSecondary" paragraph>
                                            {company.description}
                                        </Typography>
                                    </Box>
                                )}
                                <Box flexDirection={"row"} display={"flex"}>
                                    {company && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            {company.website_url && (
                                                <>
                                                    <Favorite sx={{ mr: 1, color: 'primary.main' }} />
                                                    <Typography variant="body2" color="textSecondary">
                                                        {company.website_url}
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">{job.location}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <AttachMoney sx={{ mr: 1, color: 'primary.main' }} />
                                        <Typography variant="body2">{job.salary}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                                sx={{ ml: 'auto' }}
                            >
                                Apply Now
                            </Button>
                            <Box sx={{ ml:2, mb: 2 }}>
                                <Button onClick={handleViewInterviewees} variant="contained" color="primary">
                                    Mülakat sonuçları
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Job Description */}
                    <Card variant="outlined" sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Description</Typography>
                            <Typography paragraph>{job.description}</Typography>
                        </CardContent>
                    </Card>

                    {/* Keywords */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>Job Keywords</Typography>
                        <Box>
                            {keywords.map((keyword, index) => (
                                <Chip key={index} label={keyword.keyword} sx={{ mr: 1, mb: 1 }} />
                            ))}
                        </Box>
                    </Box>

                    {/* Requirements */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>Job Requirements to evaluate</Typography>
                        <Box>

                            {requirements.map((keyword, index) => (
                                <React.Fragment key={keyword.requirement}>
                                    <ListItem>
                                        <ListItemText
                                            primary={`${index + 1}. ${keyword.requirement}`}
                                            primaryTypographyProps={{ variant: 'body1' }}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>

                    {/* Additional Links */}


                    <JobQuestionsList jobId={Number(jobId)} />
                </Box>
            ) : (
                <Typography variant="body1">Job not found</Typography>
            )}
        </Layout>
    );
};

export default JobDetail;
