import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchCompanyById, fetchJobsByCompany, updateCompany, deleteCompany, Job, Company } from '../api/api';
import JobListItem from '../components/JobListItem';
import { Container, Typography, Button, Card, CardContent, Box, Grid, Divider, CircularProgress, Alert, IconButton, Menu, MenuItem } from '@mui/material';
import Layout from '../components/Layout';
import { Edit, Delete, Add, LocationOn, Link as LinkIcon, Settings as SettingsIcon } from '@mui/icons-material';
import JobListingsSection from '../components/JobListingsSection';

// Default icons
const DEFAULT_BANNER_URL = 'https://picsum.photos/1200/400?random=1';
const DEFAULT_ICON_URL = 'https://picsum.photos/120/120?random=1';

const CompanyDetail: React.FC = () => {
    const { companyId } = useParams<{ companyId: string }>();
    const [company, setCompany] = useState<Company | null>(null);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const loadCompany = async () => {
            try {
                const companyData = await fetchCompanyById(Number(companyId));
                console.log(companyData)
                setCompany(companyData);
                const jobsData = await fetchJobsByCompany(Number(companyId));
                setJobs(jobsData);
            } catch (error) {
                setError('Failed to load company details or jobs');
            } finally {
                setLoading(false);
            }
        };

        loadCompany();
    }, [companyId]);

    const handleUpdate = async () => {
        try {
            if (company) {
                const updatedCompany = await updateCompany(Number(companyId), {
                    name: company.name,
                    description: company.description,
                    location: company.location,
                    websiteUrl: company.website_url,
                });
                setCompany(updatedCompany);
                setError(null);
            }
        } catch (error) {
            setError('Failed to update company');
        }
    };

    const handleDeactivate = async () => {
        try {
            await deleteCompany(Number(companyId));
            // Handle redirection or state update after deletion if needed
        } catch (error) {
            setError('Failed to deactivate company');
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                {company ? (
                    <Box>
                        {/* Background Banner */}
                        <Box
                            sx={{
                                position: 'relative',
                                height: '250px',
                                backgroundImage: `url(${company.banner_url || DEFAULT_BANNER_URL})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: 2,
                                mb: 4,
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-start',
                                padding: '20px',
                            }}
                        >
                            {/* Company Icon */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: '20px',
                                    left: '20px',
                                    width: '120px',
                                    height: '120px',
                                    borderRadius: '50%',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    boxShadow: 3,
                                    border: '4px solid #fff',
                                }}
                            >
                                <img
                                    src={company.icon_url || DEFAULT_ICON_URL}
                                    alt={`${company.name} Icon`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                            {/* Company Info */}
                            <Box sx={{ color: '#fff', ml: '160px' }}>
                                <Typography variant="h3" component="h1" gutterBottom>
                                    {company.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <LocationOn sx={{ mr: 1 }} />
                                    <Typography variant="h6">{company.location || 'Location not specified'}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LinkIcon sx={{ mr: 1 }} />
                                    <Typography
                                        rel="noopener noreferrer"
                                        sx={{
                                            textDecoration: 'none',
                                            color: '#fff',
                                            fontSize: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {company.website_url || 'Website not available'}
                                    </Typography>
                                </Box>
                            </Box>
                            <IconButton
                                sx={{ position: 'absolute', top: '20px', right: '20px', color: '#fff' }}
                                onClick={handleMenuClick}
                            >
                                <SettingsIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                PaperProps={{ sx: { width: '200px' } }}
                            >
                                <MenuItem onClick={handleUpdate}>
                                    <Edit sx={{ mr: 1 }} /> Update Company
                                </MenuItem>
                                <MenuItem onClick={handleDeactivate}>
                                    <Delete sx={{ mr: 1 }} /> Pause Deactivate it
                                </MenuItem>
                                <MenuItem component={Link} to={`/companies/${companyId}/add-job`}>
                                    <Add sx={{ mr: 1 }} /> Add Job
                                </MenuItem>
                            </Menu>
                        </Box>

                        {/* Company Details */}
                        <Card variant="outlined" sx={{ mb: 4 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    About Us
                                </Typography>
                                <Typography paragraph>{company.description || 'The Company where dreams come true'}</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Sector
                                        </Typography>
                                        <Typography paragraph>{company.sector || 'Not specified'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Number of Employees
                                        </Typography>
                                        <Typography paragraph>{company.num_employees || 'Not specified'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Created On
                                        </Typography>
                                        <Typography paragraph>{company.creation_date || 'Not specified'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Company Type
                                        </Typography>
                                        <Typography paragraph>{company.type || 'Not specified'}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Contact Information
                                        </Typography>
                                        <Typography paragraph>{company.contact_info || 'Not specified'}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Box>
                            {/* Other parts of the component */}
                            <JobListingsSection jobs={jobs} />
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">Company not found</Typography>
                )}
            </Container>
        </Layout>
    );
};

export default CompanyDetail;
