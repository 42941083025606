import React, { useState } from 'react';
import { addQuestion } from '../api/api';
import { TextField, Button, Typography, FormControl, FormHelperText, Card, CardContent, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface QuestionFormProps {
    categoryId: number;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ categoryId }) => {
    const [questionText, setQuestionText] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!questionText.trim()) {
            setError('Question text is required');
            return;
        }
        try {
            await addQuestion(categoryId, questionText);
            setQuestionText('');
            setError(null);
            alert('Question added successfully');
        } catch (err) {
            setError('Failed to add question');
        }
    };

    return (
        <Card sx={{ borderRadius: '12px', boxShadow: 3, p: 2 }}>
            <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                    <AddCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                        Add a New Question
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal" error={!!error}>
                        <TextField
                            id="questionText"
                            label="Enter your question"
                            variant="outlined"
                            value={questionText}
                            onChange={(e) => setQuestionText(e.target.value)}
                            helperText={error}
                            error={!!error}
                            required
                            sx={{ mb: 2 }}
                        />
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            borderRadius: '20px',
                            mt: 2,
                            bgcolor: 'purple',
                            '&:hover': {
                                bgcolor: 'darkpurple',
                            },
                        }}
                    >
                        Add Question
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default QuestionForm;
